// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";
import Alpine from "alpinejs";

if (process.env.NODE_ENV !== "production") {
  require("dotenv").config();
}

window.Alpine = Alpine;

document.addEventListener("alpine:init", () => {
  Alpine.data("getBooks", () => ({
    books: [],
    async init() {
      const apiUrl = process.env.BASEROW_URL;
      const apiKey = process.env.BASEROW_API_KEY;

      const headers = new Headers();
      headers.append("Authorization", `Token ${apiKey}`);

      fetch(apiUrl, { headers })
        .then((response) => response.json())
        .then((response) => {
          this.books = response.results;
        })
        .catch((err) => console.log(err));
    },
  }));
});

Alpine.start();

// Bootstrap tooltips
const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);
[...tooltipTriggerList].map(
  (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
);

// AOS

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

AOS.init();
